import React, { Fragment, useState ,  useRef, useEffect} from "react";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert";

import { getProductosByCode } from "../repository";
import ProductItem from "../components/ProductItem";

const SearchByCode = (props) => {
  
  const [equivalencias, setEquivalencias] = useState(false);  
  const [codigo, setCodigo] = useState("");
  const [loading, setLoading] = useState(0);
  const [productos, setProductos] = useState([]);
  const [status, setStatus] = useState(null);  
  const [automatic, setAutomatic] = useState(0);
  
  var codigoaBuscar;
  if(props.codigo !== undefined)
    {
        codigoaBuscar = props.codigo;     
        //AutomaticSearch(); 
    }

    useEffect(() =>
      {
          if(automatic === 0){
            setCodigo(codigoaBuscar);
            console.log(codigoaBuscar);
            AutomaticSearch();
            
              setAutomatic(1);
          
          }
       // console.log(productos);
      
      },[automatic, codigo]);
      
  
const validator = useRef(
  new SimpleReactValidator({
    element: (message) => (
      <span className="text-danger">
        <i className="fa fa-exclamation-circle" aria-hidden="true" />
        {` ${message}`}
      </span>
    ),
    messages: {
      default: "Campo Requerido",    
    },
  })
);  

const toggleEquivalencias = () => {
   setEquivalencias(!equivalencias);
  };



      
  //Search Products
  const AutomaticSearch = () => {
    //e.preventDefault();
      console.log("entre a la busqueda automatica");
     
    setLoading(1);
    setProductos([]);
    setStatus(null);   
         
    
      getProductosByCode(props.codigo,  equivalencias === true ? 1 : 0
      )
        .then((response) => {       
          if (response.status === "success") {
            setStatus("success");
            setProductos(response.result);
            setLoading(0);                    
          } else {
            setLoading(0);          
            Swal(
              "Error obtener la información [Productos]",
              response.message,
              "error"
            );
          }
        })
        .catch((err) => {
          console.log(err);
         setLoading(0);
          Swal("Error obtener la información [Productos]", err, "error");
        });        
   
  };
   
      


  //Search Products
  const search = (e) => {
    e.preventDefault();
    setLoading(1);
    setProductos([]);
    setStatus(null);   
    if (validator.current.allValid()) {          
      getProductosByCode(codigo,  equivalencias === true ? 1 : 0
      )
        .then((response) => {       
          if (response.status === "success") {
            setStatus("success");
            setProductos(response.result);
            setLoading(0);                    
          } else {
            setLoading(0);          
            Swal(
              "Error obtener la información [Productos]",
              response.message,
              "error"
            );
          }
        })
        .catch((err) => {
         setLoading(0);
          Swal("Error obtener la información [Productos]", err, "error");
        });        
    } else {   
      validator.current.showMessages();     
      setLoading(0);      
    }
  };
    return (
      <div>
        {/* CONTENT */}
        <section id="searchContent" className="mt-3">
          <form onSubmit={search}>
            <div className="container">
              <div className="row">
                <div className="col-md-1 mt-2">
                  <h6>Código:</h6>
                </div>
                <div className="col-md-3">
                  <input
                    name="txtCodigo"
                    type="text"
                    className="form-control"
                    defaultValue={codigo}
                  
                    /* ref={codigoRef}*/                  
                    autoComplete="off"
                    placeholder="Capture el Código"
                    onChange={(e) => {setCodigo(e.target.value); setProductos([]); setStatus(null); }}
                    maxLength="20"
                  />
                  {validator.current.message(
                    "txtCodigo",
                    codigo,
                    "required|alpha_num_dash"
                  )}
                </div>
                <div className="col-md-3 custom-control custom-checkbox mt-2 ml-3 mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    name="chkEquivalencias"
                    id="chkEquivalencias"
                    onChange={toggleEquivalencias}
                    checked={equivalencias}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="chkEquivalencias"
                  >
                    Procesar Equivalencias
                  </label>
                </div>
                <div className="col-md-3">
                  <button className="btn btn-warning btn-block" type="submit">
                    <i className="fas fa-search mr-1"></i>Buscar
                  </button>
                </div>
              </div>
              {loading === 1 && (
                <div className="text-center mb-3 mt-3">
                  <h6>Cargando...</h6>
                  <div
                    className="spinner-border text-warning"
                    role="status"
                    aria-hidden="true"
                  ></div>
                </div>
              )}
              {status === "success" && (
                <Fragment>
                  <div className="row mt-3">
                    <div className="col-md-3"></div>
                    <div className="col-md-6 text-center">
                      <h6>
                        <span className="badge badge-warning ml-1 mr-1">
                          {productos.length}
                        </span>
                        <small>
                          <strong>Producto(s) encontrado(s)</strong>
                        </small>
                      </h6>
                      <div className="col-md-3"></div>
                    </div>
                  </div>
                  <div className="row">
                    {productos.map((item, index) => (
                      <ProductItem
                        item={item}
                        index={index}
                        add={props.addToCart}
                        key = {index}
                      />
                    ))}
                  </div>
                </Fragment>
              )}
            </div>
          </form>
        </section>
      </div>
    );
  };

export default SearchByCode;
