import React, { Component, Fragment } from "react";
class Header extends Component {
  render() {
    var userIsLogOn = false;
    var userLogged = "INVITADO";
    if (sessionStorage.getItem("usuario") !== null) {
      var usuario = JSON.parse(sessionStorage.getItem("usuario"));
      userLogged = usuario[0].Nombre;
      userIsLogOn = true;
    }
 /*   const Redirigir = (e) => {
      e.preventDefault();
    };
    */

    return (
      <header id="main-header">
        <div className="container">
          <div className={this.props.class1}>
            <div className={this.props.class2}>
              <h4 className="mt-2">
                <i className={this.props.class3}></i>
                &nbsp;{this.props.title}
              </h4>
            </div>
            <div className="col-md-6 py-1">
              <div className="float-right"></div>
            </div>
          </div>
          <div className="row bg-light py-1">
            <div className=  {userIsLogOn===true ? "col-md-12" : "col-md-8" }     >
              <div className="float-right mr-2">
                Bienvenido:
                {/*<i className="fas fa-user"></i>  */}
                &nbsp;
                {userLogged
                  ? userLogged.length > 30
                    ? userLogged.substring(0, 30).toUpperCase() + "..."
                    : userLogged.toUpperCase()
                  : "GUEST"}
              </div>
            </div>

            <div className="col">
              {userIsLogOn === false && (
                <Fragment>
                  <div className="row">
                  <div className="col float-right ">
                      <button
                        className="btn btn-block btn-sm btn-success  "
                        name="btnLogin"
                        hidden={userIsLogOn}
                        onClick={(e) => {
                          window.location = "Login";
                        }}
                      >
                        <i className="fa fa-sign-in"></i>
                        &nbsp; Iniciar Sesión
                      </button>
                    </div>
                    <div className="col float-right">
                      <button
                        className="btn btn-block btn-sm btn-primary  "
                        name="btnRegistrar"
                        hidden={userIsLogOn}
                        onClick={(e) => {
                          window.location = "Register";
                        }}
                      >
                        <i className=" fa fa-user-plus"></i>
                        &nbsp; Registrarse
                      </button>
                    </div>
                
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
