import React, { Component, Fragment } from "react";
import { NavLink, Link } from "react-router-dom";
import Swal from "sweetalert";
import NavBarItem from "./NavBarItem";
import { getNumPedidos, getPrivilegios } from "../repository";

class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      cartItems: 0,
      orderItems: 0,
      privilegios: [],
      esAdmin: false,
      userIsLogged:false,
    };
    this.obtienePrivilegios.bind(this);
  }

  obtienePrivilegios = () => {
    var priv = JSON.parse(sessionStorage.getItem("privilegios"));
    if(priv===null)
    {
      getPrivilegios().then((response) =>
      {     
          sessionStorage.setItem("privilegios", JSON.stringify(response.result));  
          this.setState({privilegios: JSON.parse(JSON.stringify(response.result)),});
      });
     
    }else
    {
      this.setState({privilegios: priv,});
    }
    var user = JSON.parse(sessionStorage.getItem("usuario"));
    if (user !== null) {
      var esAdmini = user[0].EsAdmin;
      this.setState({userIsLogged:true});
      if (esAdmini) {
        this.setState({ esAdmin: true });
      }
    }
  };

  logOut() {
    sessionStorage.removeItem("usuario");
    sessionStorage.removeItem("cart");
    sessionStorage.clear();
  }

  getCartItems = () => {
    if (sessionStorage.getItem("cart") !== null) {
      let cart = JSON.parse(sessionStorage.getItem("cart"));
      this.setState({
        cartItems: Object.keys(cart).length,
      });
    }
  };

  
  getNumOrders = () => {
    var user = JSON.parse(sessionStorage.getItem("usuario"));   
     if(user !== null)
     {
    var idCliente = user[0].idCliente;
    var idAlmacen= user[0].idAlmacen;
    getNumPedidos(idCliente, idAlmacen)
      .then((response) => {
        if (response.status === "success") {         
          this.setState({
            status: "success",
            orderItems: response.result[0].NumPedidos,
          });         
        } else {
          Swal(
            "Error obtener la información [Pedidos]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Pedidos]", err, "error")
      );
    }
  };


  componentDidMount() {
    this.obtienePrivilegios();
    this.getCartItems();
      this.getNumOrders();
  }

  render() {
    // var priv = sessionStorage.getItem("privilegios") !== null ? sessionStorage.getItem("privilegios") : [];
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark p-2 sticky-top">
        <div className="container">
          <NavLink to="/Search" className="navbar-brand mb-2 text-white-50">
            &nbsp;Tienda GOVI
          </NavLink>

          <Link to="/cart" className="navbar-toggler text-white">
            <i className="fas fa-shopping-cart mr-2"></i>
            <span className="badge badge-success">{this.state.cartItems}</span>
          </Link>

          <button
            className="navbar-toggler navbar-toggler-right"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
          >
            <small className="mr-2">MENU</small>
            <i className="fas fa-bars"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav">

            {this.state.userIsLogged===true && (
            <li className="nav-item px-2">
              <NavLink
                    to="/Home"
                    className="nav-link "
                    activeClassName="nav-link active"
                  ><h6>Inicio
                  </h6>
                  </NavLink>
              </li>)}
            
              {this.state.privilegios.map((item, index) => (
                <NavBarItem
                  item={item}
                  key={index}
                  cartItems={this.state.cartItems}
                  orderItems={this.state.orderItems}
                />
              ))}
            </ul>
            {this.state.esAdmin === true && (
              <ul className="navbar-nav ml-2">
                <li className="nav-item">
                  <NavLink
                    to="/Admon"
                    className="nav-link"
                    activeClassName="nav-link active"
                    href="/Admon"
                  >
                    <h6> Administración</h6>
                  </NavLink>
                </li>
              </ul>
            )}
            {this.state.userIsLogged===true && (
             <Fragment>
              <ul className="navbar-nav ml-2">
              <li className="nav-item ">
              <NavLink
                    to="/Orders"
                    className="nav-link "
                    activeClassName="nav-link active"
                  ><h6>Pedidos
                 {" "}
                  <span className="badge badge-warning">{this.state.orderItems}</span>
                  </h6>
                  </NavLink>
              </li>
            </ul>
            
            <ul className="navbar-nav ml-2">
              <li className="nav-item">
              <NavLink
                    to="/Shipping"
                    className="nav-link "
                    activeClassName="nav-link active"
                  ><h6>Envío
                  </h6>
                  </NavLink>
              </li>
            </ul>

            <ul className="navbar-nav ml-2">
              <li className="nav-item">
                <a className="nav-link" href="/" onClick={this.logOut}>
                  <h6>Salir</h6>
                </a>
              </li>
            </ul>
            </Fragment>
            )}

            </div>

            <ul className="navbar-nav ml-2 float-right">
              <li className="nav-item">
                <a className="nav-link" href="https://wa.me/528118068284" target="_new">
                  <div className="row justify-content-start">
                    <div className="col d-flex text-align-left">
                    <i className="fa fa-whatsapp"></i> &nbsp;
                    <small>+52 (55) 5031-3427</small>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
            <ul className="navbar-nav ml-2 float-right" target="_new">
              <li className="nav-item">
                <a className="nav-link" href="tel:8000014762">
                  <div className="row justify-content-start">
                    <div className="col d-flex text-align-left">
                    <i className="fa fa-phone"></i>  &nbsp;
                      <small> 01(800) 001-4762</small>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
            
          
         
        </div>
      </nav>
    );
  }
}

export default NavBar;
