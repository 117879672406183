import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./screens/Login";
import Home from "./screens/Home";
import Search from "./screens/Search";
import SearchWithCode from "./screens/SearchWithCode";
import Cart from "./screens/Cart";
import OrderDetails from "./components/OrderDetails";
import OrderDetailsAdmon from "./components/OrderDetailsAdmon";
import Orders from "./screens/Orders";
import Stock from "./screens/Stock";
import NotFound from "./screens/NotFound";
//import { isAuthenticated } from "./repository";
import Users from "./screens/Users"
import Forgot from "./screens/Forgot"; 
import Register from "./screens/Register";
import UserInformation from "./components/UserInformation";
import Admon from "./screens/Admon";

class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        {/* Configuración de Rutas y Páginas */}
        <Switch>      
          <Route exact path="/" component={Search} />     

          <PrivateRoute exact path="/home">
            <Home />
          </PrivateRoute>
            <PrivateRoute exact path ="/Admon" >
              <Admon/>
            </PrivateRoute>

          <PrivateRoute exact path="/Login">
            <Login />
          </PrivateRoute>

          <PrivateRoute exact path="/Search">
            <Search />
          </PrivateRoute>
         
          <PrivateRoute exact path="/Register">
            <Register />
          </PrivateRoute>
         
         <PrivateRoute exact path ="/Forgot">
        <Forgot/>
         </PrivateRoute>

         <PrivateRoute exact path ="/Shipping">
        <UserInformation/>
        </PrivateRoute>

          <PrivateRoute exact path="/cart">
            <Cart />
          </PrivateRoute>

          <Route
            exact
            path="/search/:codigo"
            render={props => <SearchWithCode {...props} />}
          />

          <Route
            exact
            path="/orderDetails/:idPedido/:source"
            render={props => <OrderDetails {...props} />}
          />
            <Route
            exact
            path="/orderDetailsAdmon/:idPedido/:source"
            render={props => <OrderDetailsAdmon {...props} />}
          />

          <PrivateRoute exact path="/orders">
            <Orders />
          </PrivateRoute>

          <PrivateRoute exact path="/stock">
            <Stock />
          </PrivateRoute>        
          <PrivateRoute exact path="/Users">
            <Users />
          </PrivateRoute>

          <Route component={NotFound} />

          {/*
          <PrivateRoute exact path="/test">
            <Test />
          </PrivateRoute>
          */}
        </Switch>
      </BrowserRouter>
    );
  }
}

//const auth = isAuthenticated();

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...props }) {
  //console.log("auth: " + auth);


  return (
    <Route {...props} render={({ location }) =>   ( children ) }/>
  );
  /*
  return (
    <Route
      {...props}
      render={({ location }) =>
        auth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
  */
}

export default Router;
