import React, { useEffect, useState } from "react";
import Global from "./Global";
import { numberFormat } from "../repository";
import imageExists from "image-exists";
import ImageZoomViewer from "./ProductImageZoomViewer";
import Modal360Viewer from "./Product360Viewer";
import ProductApplications from "../components/ProductApplications";

const OrderItem = (props) => {
  const [index] = useState(undefined);
  const [item] = useState(props.item);
  const [imageSource, setImageSource] = useState("");
  const [is360, set360] = useState(false);
  const [isModal360Open, setIsModal360Open] = useState(false);  
  const [isImageZoom, setIsImageZoom] = useState(false);
  const [codeSelected, setCodeSelected] = useState(null);

  const openImageZoomModal = () => {
    if (imageSource !== Global.foto_nd) setIsImageZoom(true);
  };

  function openAppsModal(e) {
    e.preventDefault();
    setCodeSelected(item);  
  }

  const closeAppsModal = () => {
    setCodeSelected(null);
  };

  const closeImageZoomModal = () => {
    setIsImageZoom(false);
  };

  const open360Modal = (e) => {
    e.preventDefault();
    setIsModal360Open(true);
  };

  const close360Modal = () => {
    setIsModal360Open(false);
  };

  useEffect(() => {
    renderImageSource(item.Producto);
  }, [item.Producto]);

  const renderImageSource = (codigo) => {
    imageExists( Global.urlFotos360 + codigo.toUpperCase() + "/images/img_0_0_0.jpg",
      function (exists) {
        if (exists) {
          setImageSource(Global.urlFotos360 + codigo.toUpperCase() +"/images/img_0_0_0.jpg"
          );
          set360(true);
        } else {
          imageExists(
            Global.fotos_URL + codigo.toLowerCase().replace("-", "_") + ".jpg",
            function (exists) {
              if (exists) {
                setImageSource(
                  Global.fotos_URL +
                    codigo.toLowerCase().replace("-", "_") +
                    ".jpg"
                );
              } else {
                setImageSource(Global.foto_nd);
              }
            }
          );
        }
      }
    );
  };

  return (
    <li className="list-group-item">
      <div className="row">
        <div className="col-md-3 text-center">
          <img
            className="card-img-top"
            style={{
              cursor: "pointer",
              maxWidth: 100 + "%",
              height: 170 + "px",
              objectFit: is360 ? "cover" : "contain",
            }}
            src={imageSource}
            alt={item.Codigo}
            onClick={() => openImageZoomModal()}
            data-toggle="modal"
            data-target={"#modalImageZoom" + item.Codigo}
          />
          {isImageZoom && (
            <ImageZoomViewer
              item={item}
              imageSource={imageSource}
              closeModal={closeImageZoomModal}
            />
          )}

          <div className="col align-self-center mt-3">
            <span>             
              {is360 && (               
                  <button
                    className="btn btn-outline-info btn-sm rounded-pill"
                    onClick={(e) => open360Modal(e)}
                    data-toggle="modal"
                    data-target={"#modal360" + item.Codigo + index}
                  >
                    360
                  </button>                
              )}
              </span>
              {codeSelected && (
                <ProductApplications
                  codigo={item.Codigo}
                  closeModal={closeAppsModal}
                />
              )}
              {isModal360Open && (
                <Modal360Viewer
                  index={index}
                  item={item}
                  catalogo={true}
                  closeModal={close360Modal}
                />
              )}
          
          </div>
        </div>
        <div className="col-md-9 text-center">
          <h5>
            <span className="badge badge-secondary float-left mt-1">
              {item.Producto}
            </span>
            <span className="mt-1">&nbsp;</span>
            <span className="badge badge-pill badge-primary float-right mt-1">
              ${numberFormat(item.Precio)}
            </span>
          </h5>
          <div className="text-center">{item.Descripcion}</div>
          <div className="text-center">
            <span className="mr-2">
              <strong>Cantidad:</strong>
            </span>
            {item.Cantidad}
          </div>
        </div>
      </div>
    </li>
  );
};

export default OrderItem;
