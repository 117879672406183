var Global = {  
  //API REST Desarrollo
  //apiRESTUrlNET: "http://localhost:62991/api", //Usuarios ASP.NET Membership
  //apiRESTUrl: "http://localhost:3001/api", //Azure Node.JS  
  
  //API REST Produccion
  apiRESTUrlNET: "https://webapi-ecommerce-net.azurewebsites.net/api", //Usuarios ASP.NET Membership
  apiRESTUrl: "https://webapi-ecommerce-node.azurewebsites.net/api", //Azure Node.JS  

  //inventarios WEBAPI pedidosWEB
  //apiRESTUrlNodeJS : "http://207.248.42.102:3000/api",


  /*************** Generales *****************/
  fotos_URL: "https://storagegovi.blob.core.windows.net/fotos/i_",
  
  urlFotos360: "https://storagegovi.blob.core.windows.net/360/12-2021/",

  foto_no_disponible:
    "https://storagegovi.blob.core.windows.net/360/no_disponible/images/img_0_0_0.jpg",
  foto_nd: "https://storagegovi.blob.core.windows.net/fotos/img_nd.png",

  precioEnvio: 86,
};

export default Global;
