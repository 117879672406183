import React, { Fragment, useState , useEffect} from "react";
import Swal from "sweetalert";

import { getRecomendados } from "../repository";
import ProductItem from "../components/ProductItem";

const Recomendations = (props) => {
  
 // const [equivalencias, setEquivalencias] = useState(false);  
  
  const [loading, setLoading] = useState(0);
  const [productos, setProductos] = useState([]);
  const [status, setStatus] = useState(null);  
  


useEffect(() => {
  setLoading(1);
  getRecomendados().then((response) => 
  {
    setProductos(response.result); 
    setLoading(0); 
    setStatus("success");}
    ).catch((err) =>
    {
      Swal("Error obtener la información [ProductosInicio]", err, "error")
    }
);   
}, []);

    return (
      <div>
        {/* CONTENT */}
        <section id="searchContent" className="mt-3">
          <form >
            <div className="container">            
              {loading === 1 && (
                <div className="text-center mb-3 mt-3">
                  <h6>Cargando...</h6>
                  <div
                    className="spinner-border text-warning"
                    role="status"
                    aria-hidden="true"
                  ></div>
                </div>
              )}
              {status === "success" && (
                <Fragment>
                  <div className="row mt-3">
                    <div className="col-md-3"></div>
                    <div className="col-md-6 text-center">
                      <h6>
                        <span className="badge badge-warning ml-1 mr-1">
                          {productos.length}
                        </span>
                        <small>
                          <strong>Producto(s) encontrado(s)</strong>
                        </small>
                      </h6>
                      <div className="col-md-3"></div>
                    </div>
                  </div>
                  <div className="row">
                    {productos.map((item, index) => (
                      <ProductItem
                        item={item}
                        index={index}
                        add={props.addToCart}
                        key = {index}
                      />
                    ))}
                  </div>
                </Fragment>
              )}
            </div>
          </form>
        </section>
      </div>
    );
  };

export default Recomendations;
