import React, { Fragment, useEffect, useState, useMemo } from "react";
import Swal from "sweetalert";
import Header from "../components/Header";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Table from "../components/Table";
import { getPedidos } from "../repository";

const Orders = () => {
  const [pedidos, setPedidos] = useState({});
  const [status, setStatus] = useState(null);

  /* 
    - Columns is a simple array right now, but it will contain some logic later on. It is recommended by react-table to memoize the columns data
    - Here in this example, we have grouped our columns into two headers. react-table is flexible enough to create grouped table headers
  */
 const columns = useMemo(
  () => [
    {    
       
        Header: "MIS PEDIDOS",
       columns: [
        {
          Header: "Pedido",
          accessor: "idPedido"
        } ,                                            
          {
            Header: "Total",
            accessor: "Total"
          } ,
          {
            Header: "Fecha",
            accessor: "Fecha"
          } ,
          {
            Header: "Guía",
            accessor: "NumeroGuia"
          } ,
          {
            Header: "",
            accessor: "boton"
          } ,
      ]
    }
  ],
  []
);

  const obtienePedidos = () => {
    var user = JSON.parse(sessionStorage.getItem("usuario"));    
    if (user !== null){
    var idClienteWeb = user[0].idCliente;
    var idAlmacen = user[0].idAlmacen; 
        getPedidos(idClienteWeb, idAlmacen,50000)
      .then((response) => {
        if (response.status === "success") {
          setStatus("success");
          setPedidos(response.result);
        } else {
          Swal(
            "Error obtener la información [Pedidos]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Pedidos]", err, "error")
      );
    }
    else
    {
      setStatus("success");
      setPedidos([]);
    }
  };

  useEffect(() => {
    obtienePedidos();
  }, []);

  return (
    <div>
      {/* NAVBAR */}
      <NavBar />
      {/* HEADER */}
      <Header
        title="Pedidos"
        class1="row py-1 bg-warning text-black"
        class2="col-md-6"
        class3="fa fa-money-check-alt"
      />
      <section id="posts">
        <div className="container">
          <div className="row mt-3">
            {status !== "success" && (
              <div className="col-md-12 text-center">
                <h6>Cargando...</h6>
                <div
                  className="spinner-border text-warning"
                  role="status"
                  aria-hidden="true"
                ></div>
              </div>
            )}
            {status === "success" && pedidos.length === 0 && (
              <Fragment>
                <div className="col-md-3"></div>
                <div className="col-md-6 text-center">
                  <small>
                    <strong>No se encontró información.</strong>
                  </small>
                  <div className="col-md-3"></div>
                </div>
              </Fragment>
            )}
            
            {status === "success" && pedidos.length > 0 && (
              <div className="col">
               {/* <div className="card"> */ }
                <Table columns={columns} data={pedidos} />
                  {/*
                  <table className="table-sm table-striped w-100">
                    <thead className="table-dark">
                      <tr>
                        <th className="text-center">#</th>
                        <th className="text-center">Total</th>
                        <th className="text-center">Fecha</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pedidos.map((item) => {
                        return (                         
                          <tr key={item.idPedido}>
                            <td className="text-center">{item.idPedido}</td>
                            <td className="text-right">
                              <strong>${numberFormat(item.Total)}</strong>
                            </td>
                            <td className="text-center">
                              <Moment format="DD/MM/YY">{item.Fecha}</Moment>
                            </td>
                            <td className="text-center">
                              <Link
                                to={"/orderDetails/" + item.idPedido + "/2"}
                                className="btn btn-secondary"
                              >
                                <i className="fas fa-angle-double-right"></i>{" "}
                                Detalle
                              </Link>
                            </td>
                          </tr>                          
                        );
                      })}
                    </tbody>
                  </table>
                    */}
                {/*</div> */}
              </div>
            )}
          </div>
        </div>
      </section>
      {/* FOOTER */}
      <Footer />
    </div>
  );
};

export default Orders;
